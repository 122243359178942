@import "variables";
@import "../styles/mixins";

.card {
  width: 100%;
  background: $color-white;
  border-radius: $border-radius;
  font-family: $font-soleil;
  position: relative;

  .title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 60px;
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: 1px solid $color-purple-00;
    overflow: hidden;
    background: $color-white;
    z-index: 1;

    @include box-shadow($shadow-blur-sm, $shadow-blue-2);

    .label {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 30px;
      font-size: 2rem;
      font-family: $font-soleil-light;

      @extend .font-fix;
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 25px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding: 20px 30px;

    &.noPadding {
      padding: 0;
    }
  }

  @include box-shadow($shadow-blur-md, $shadow-blue-2);
}
