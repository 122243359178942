@import "variables";
@import "mixins";

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  > div {
    height: 57px;

    .suggestions {
      transition: all .2s linear;

      &.hidden {
        transform: translateY(14px);
      }
    }
  }

  .avatar {
    display: flex;
    justify-content: center;
  }

  .user {
    display: flex;
    justify-content: center;
    padding-top: 4px;
    flex: 1 0 0;
  }

  .duration {
    padding-top: 4px;
    flex: 0.7 0 0;
  }

  .time {
    padding-top: 4px;
    flex: 0.7 0 0;
  }

  .timeDash {
    padding-top: 4px;
  }

  .title {
    padding-top: 4px;
    flex: 3 0 0;
  }

  .project {
    padding-top: 4px;
    flex: 2 0 0;
  }

  .tag {
    padding-top: 4px;
    flex: 1 0 0;
  }

  .button {
    flex: 0 0 55px;
  }
}
