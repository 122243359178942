@import "variables";

.icon {
  display: inline-block;
  height: var(--size);
  width: var(--size);

  &.onClick {
    cursor: pointer;
  }

  svg {
    height: var(--size);
    width: var(--size);
  }

  &.animation {
    g,
    path {
      transition: fill $transition-time $transition-easing;
    }
  }

  g,
  path {
    fill: var(--color);
  }
}
