@import "variables";
@import "mixins";

.success {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  @keyframes radzio {
    0% {
      opacity: 0;
      transform: scale(0.5) translateY(300px);
    }

    20% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }

    80% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(-500px);
    }
  }

  @keyframes radzio-svg {
    0% {
      transform: translateY(0);
    }

    33% {
      transform: translateY(20px);
    }

    66% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(20px);
    }
  }

  .radzio {
    position: fixed;
    top: 50vh;
    left: 50vw;
    margin-left: -180px;
    margin-top: -180px;
    transform-origin: 60% 100%;
    animation: radzio 2.5s cubic-bezier(0.77, 0, 0.175, 1) 0s 1;
    animation-fill-mode: both;

    img {
      animation: radzio-svg 1.5s linear 0.5s 1;
      animation-fill-mode: both;
    }
  }
}
