@import "variables";
@import "mixins";

.listFooter {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;

  &.docked {
    box-shadow: none;
  }
}
