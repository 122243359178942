@import "variables";
@import "mixins";

.block {
  display: block;
  width: 100%;
  position: relative;

  .row {
    display: flex;
    flex-direction: row;

    .cell {
      flex: 1;
      padding: 20px;

      &.right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  .btnContainer {
    padding-top: 0 !important;

    .saveBtn {
      padding-left: 40px;
    }
  }
}
