@import "variables";
@import "mixins";

.description {
  font-size: 1.4rem;

  span {
    font-family: $font-soleil-bold;
  }
}
