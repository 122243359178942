@import "variables";
@import "mixins";

.infoCardWrapper {
  margin-top: 40px;
}

.row {
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;

  &:last-child {
    margin-bottom: 0;
  }
}