@import "variables";
@import "mixins";

.listActions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 0 20px 0 0;
  background: linear-gradient(to right, rgba($color-white, 0) 0%, rgba($color-white, 1) 50%);
  position: absolute;
  z-index: 3;
  top: 5px;
  right: 5px;
  bottom: 5px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s $transition-easing 0s;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-left: 24px;
    opacity: 0;
    transform: scale(0);
    transition: all 0.2s $transition-easing;
    cursor: pointer;

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        transition-delay: #{$i*0.1}s;
      }
    }

    svg {
      border: 1px solid transparent;
      transition: all 0.2s $transition-easing;
      opacity: 0.5;
    }

    &.color {
      &-grey {
        border-radius: 50%;
        background: $color-sage-40;
      }

      &-blue {
        border-radius: 50%;
        background: $color-blue-70;

        @include box-shadow($shadow-blur-md, $shadow-blue-2);
      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.2s $transition-easing 0.2s;

    a {
      opacity: 1;
      transform: scale(1);

      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
  }
}
