@import "variables";
@import "mixins";

.filters {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: $color-white;

  > div {
    flex: 1;
  }
}

.select {
  width: 100%;
  margin-right: 16px;
  margin-top: 24px;

  .suggestions {
    transition: all .2s linear;

    &.hidden {
      transform: translateY(14px);
    }
  }
}
