@import "variables";
@import "mixins";

.row {
  display: flex;
  flex-direction: row;
  margin: 0 -15px;
}

.column {
  flex: 1;
  padding: 20px 15px;
}
