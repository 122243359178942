@import "variables";
@import "mixins";

.subtitle {
  margin-bottom: 15px;
}

.subtitlebold {
  font-family: $font-soleil-bold;
  margin-bottom: 15px;
}
