@import "variables";
@import "mixins";

.colorpicker {
  position: relative;
  display: flex;
  outline-style: none;
  box-shadow: none;
  align-items: center;

  .value {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    min-height: 25px;
    min-width: 25px;
    border-radius: 50%;
    background-color: $color-white;
    transition: background 0.5s linear;
    border: 1px solid $color-sage-20;

    div {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $color-sage-40;
      transform: scale(0.7);
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $color-sage-40;
      opacity: 0;
      z-index: -1;
    }
  }

  .colorName {
    color: $color-sage-40;
    padding-left: 10px;
    min-width: 100px;

    @extend .font-fix;
  }

  @keyframes focus-anim {
    0% {
      transform: scale(0.9);
      opacity: 1;
    }

    100% {
      transform: scale(2);
      opacity: 0;
    }
  }

  &.focused {
    .value::after {
      animation: focus-anim 1s $transition-easing infinite;
    }
  }
}

.dropdown {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 150px;
  padding: 20px;
  background: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-sage-20;

  @include box-shadow($shadow-blur-lg, $shadow-black-2);

  a {
    position: relative;
    margin: 5px;
    cursor: pointer;

    div {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $color-sage-40;
      transition: transform 0.2s $transition-easing;
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 1px solid $color-sage-40;
      opacity: 0;
      transition: opacity 0.3s $transition-easing;
    }

    &.active {
      div {
        transform: scale(0.6);
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &::after {
    width: 10px;
    height: 10px;
    background: $color-white;
    border-style: solid;
    border-color: $color-sage-20;
    border-width: 1px 0 0 1px;
    transform: rotate(45deg);
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -5px;
    margin-top: -6px;
    content: '';
  }
}