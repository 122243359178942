@import "variables";
@import "mixins";

.button {
  background: transparent;
  text-transform: uppercase;
  color: $color-blue-70;
  cursor: pointer;
  border-radius: $border-radius-xl;
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-family: $font-soleil-bold;
  outline: none;
  text-decoration: none;
  transition: all .2s linear;
  box-sizing: border-box;

  span {
    @extend .font-fix;
  }

  &.color {
    &-blue {
      background: $color-blue-70;
      color: $color-white;

      &:hover {
        background: $color-blue-40;
      }

      &.disabled {
        background: $color-flint-30;
      }
    }

    &-white {
      background: $color-white;
      color: $color-blue-70;

      &:hover {
        color: $color-blue-40;
      }

      &.disabled {
        color: $color-flint-30;
      }
    }

    &-transparent {
      background: transparent;
      color: $color-blue-70;

      &:hover {
        color: $color-blue-40;
      }

      &.disabled {
        color: $color-flint-30;
      }
    }
  }

  &.size {
    &-superSmall {
      height: 27px;
      padding: 0 10px;
      font-family: $font-soleil-bold;
    }

    &-small {
      height: 35px;
      padding: 0 16px;
      font-family: $font-soleil-bold;
    }

    &-medium {
      height: 45px;
      padding: 0 32px;
    }

    &-large {
      height: 55px;
      min-width: 240px;
      border-radius: 27.5px;
      font-size: 1.6rem;
      line-height: 1.6rem;
      padding: 0 32px;
    }
  }

  &.type {
    &-filled {
      border: none;
    }

    &-bordered {
      border: solid 1px $color-sage-10;
    }

    &-plain {
      display: inline;
      color: $color-blue-70;
      padding: 0;
      background: transparent;
      text-decoration: none;
      border: none;

      &:hover {
        text-decoration: underline;
        color: $color-blue-40;
        background: transparent;
      }

      &.disabled {
        background: transparent;
        text-decoration: none;
        color: $color-blue-70;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &.shadow {
    @include box-shadow($shadow-blur-md, $shadow-grey);
  }

  @keyframes bouncing {
    0% {
      opacity: 0.7;
    }

    50% {
      opacity: 0.9;
    }

    100% {
      opacity: 0.7;
    }
  }

  &.loading {
    animation-name: bouncing;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  svg {
    margin-right: 10px;
  }

  &.iconOnly {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin: 0;
    }

    &.size {
      &-small {
        width: 35px;
        height: 35px;
      }

      &-medium {
        width: 45px;
        height: 45px;
      }

      &-large {
        width: 55px;
        min-width: 55px;
        height: 55px;
      }
    }
  }

  &:focus {
    transform: scale(1.1);

    @include box-shadow($shadow-blur-md, $shadow-black-2);
  }
}
