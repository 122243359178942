@import "variables";

.toaster {
  position: fixed;
  top: 75px;
  right: 0;
  z-index: 100;
  max-width: 496px;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
  }
}
