@import "variables";
@import "mixins";

.addTaxIdForm {
  .form {

    > div:first-of-type {
      max-width: 225px;
    }

    .row {
      margin-top: 25px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 15px;

      &.rowOne {
        > div {
          min-width: 149px;
          width: 149px;
          margin-right: 10px;
        }
      }

      &.rowTwo {
        > div {
          flex: 1;
          margin-right: 10px;
        }
      }

      &.rowThree {
        > div {
          flex: 1;
          margin-right: 10px;
        }
      }
    }

    .declaration {
      position: relative;
      padding-top: 20px;
      margin-bottom: 24px;

      label {
        font-size: 1.1rem;
        line-height: 1.4rem;
        margin-left: 20px;
        color: $color-flint-60;
      }
    }
  }

  .buttonsWrapper {
    display: flex;
    justify-content: flex-end;

    :nth-child(2) {
      width: 177px;
      margin-left: 20px;
    }
  }
}
