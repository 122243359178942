@import "variables";
@import "mixins";

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  .card {
    background: $color-white;
    color: $color-flint-60;
    border-radius: $border-radius;
    z-index: 2;
    overflow: auto;
    box-sizing: border-box;
    max-height: 80vh;

    .body {
      display: flex;
      flex-direction: row;
    }

    .left {
      width: 164px;
      margin-right: 40px;
    }

    .illustration {
      width: 164px;
      height: 164px;
    }

    .right {
      flex: 1;
    }

    &.size {
      &-large {
        width: 900px;

        .body {
          padding: 80px;
        }
      }

      &-medium {
        width: 800px;

        .body {
          padding: 72px;
        }
      }

      &-small {
        width: 680px;

        .body {
          padding: 60px;
        }
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $color-overlay;
    z-index: 1;
  }
}

.header {
  font-family: $font-soleil-bold;
  font-size: 1.8rem;
  line-height: 2rem;
}

.content {
  font-family: $font-soleil-light;
  font-size: 1.4rem;
  line-height: 2rem;
}

.actions {
  justify-content: flex-end;
}