@import "variables";

.inputDate {
  position: relative;
  font-family: $font-soleil;
  font-size: 1.2rem;
  line-height: 1.2rem;
  width: 100%;
  outline-style: none;
  box-shadow: none;
  min-width: 100px;

  label {
    color: $color-sage-40;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-family: $font-soleil-light;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 8px;
    transition: all $transition-time $transition-easing;
    transform: scale(1) translate(0, 0);
    transform-origin: left top 0;

    &:focus {
      outline: none;
    }
  }

  .hint {
    position: absolute;
    display: block;
    opacity: 1;
    left: 0;
    transition: opacity $transition-time $transition-easing 0ms;
    top: 8px;
    color: $color-sage-40;
    font-size: 1.2rem;
    line-height: 1rem;
    font-family: $font-soleil-light;
    pointer-events: none;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
  }

  .bar::before,
  .bar::after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: $color-blue-70;
    transition: transform $transition-time $transition-easing;
    transform: scaleX(0);
  }

  .bar::before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
  }

  .bar::after {
    display: none;
    right: 50%;
  }

  .errormessage {
    color: $color-error-70;
    position: absolute;
    top: 100%;
    margin-top: 10px;
    font-size: 1rem;
    line-height: 1rem;
    opacity: 0;
    transform: translateY(-10px);
    transition: all $transition-time $transition-easing;
  }

  .input {
    height: 24px;
    font-size: 1.4rem;
    color: $color-blue-70;
    font-family: $font-soleil-bold;
    padding: 5px 0;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-bottom: 1px solid $color-sage-20;
    background: transparent;
  }

  .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  /* active state */
  &:focus {
    outline: none;

    label {
      left: 0 !important;
      transform: scale(0.8) translate(0, -28px);
    }

    .bar::before,
    .bar::after {
      transform: scaleX(1);
    }
  }

  /* filled state */
  &.filled {
    label {
      left: 0 !important;
      transform: scale(0.8) translate(0, -28px);
    }

    .hint {
      display: none;
      opacity: 0;
    }
  }

  /* input with icon */
  &.iconInput {
    padding: 7px 0 7px 30px;

    label {
      left: 30px;
    }

    .hint {
      left: 30px;
    }

    .error {
      bottom: 15px;
      top: auto;
    }
  }

  &.error {
    input {
      color: $color-error-70;
    }

    .errormessage {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.narrow {
    .errormessage {
      margin-top: 4px;
    }
  }

  &.disabled {
    pointer-events: none;

    .input {
      color: $color-sage-40;
    }

    label { /* stylelint-disable-line */
      opacity: 0.5;
    }
  }
}
