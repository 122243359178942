@import "variables";
@import "mixins";

.header {
  font-family: $font-soleil-bold;
  font-size: 1.8rem;
}

.users {
  display: flex;
  flex-wrap: wrap;
  max-height: 180px;
  min-height: 40px;
  overflow: hidden;
  overflow-y: auto;
}

.member {
  display: flex;
  align-items: center;
}

.membersHeader {
  font-family: $font-soleil-bold;
  font-size: 1.2rem;
  line-height: 1.2rem;
}

.userName {
  font-size: 1.2rem;
  white-space: nowrap;
}

.email {
  width: 324px;
}