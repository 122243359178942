@import "variables";
@import "mixins";

.listContainer {
  .cell {
    display: inline-flex;
  }

  .projectsList {
    .noResultsContainer {
      padding: 50px 0;
    }

    .list {
      margin-bottom: 40px;
    }
  }

  .barContent {
    font-size: 1.2rem;
    color: $color-sage-40;

    &.accountConnected {
      color: $color-blue-70;
    }
  }

  .barContainer {
    margin-bottom: 20px;
  }

  .paddingTop50 {
    padding-top: 50px;
  }
}
