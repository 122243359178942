// colors

$color-blue-100: #001970;
$color-blue-95: #00208F;
$color-blue-90: #153CC2;
$color-blue-80: #2E55DB;
$color-blue-70: #3C63E8;
$color-blue-40: #7594FF;
$color-blue-00: #f5f7ff;

$color-purple-20: #E6ECFE;
$color-purple-10: #F2F5FF;
$color-purple-00: #F4F6FA;

$color-sage-70: #56757D;
$color-sage-40: #9BABAF;
$color-sage-20: #E3E3E3;
$color-sage-10: #F0F0F0;

$color-flint-95: #141D27;
$color-flint-80: #323F4D;
$color-flint-70: #3F4C59;
$color-flint-60: #4E5966;
$color-flint-40: #6E7680;
$color-flint-30: #939699;

$color-white: #FFFFFF;

$color-error-90: #AF0101;
$color-error-80: #FD5A5A;
$color-error-70: #DC2E2E;
$color-error-10: #F8DCDC;

$color-success-90: #005F04;
$color-success-70: #0D8112;
$color-success-10: #DDFFDE;

$color-warning-100: #574300;
$color-warning-90: #8A6A00;
$color-warning-70: #D6AA15;
$color-warning-50: #F0C430;
$color-warning-10: #FFF6D9;

$color-overlay: rgba(110, 118, 128, 0.64);

// fonts
$font-soleil: 'Soleil', sans-serif;
$font-soleil-light: 'Soleil Light', sans-serif;
$font-soleil-bold: 'Soleil Bold', sans-serif;

// box-shadow variables
$shadow-blur-sm: 7px;
$shadow-blur-md: 13px;
$shadow-blur-lg: 38px;

$shadow-black-1: rgba(0, 0, 0, 0.29);
$shadow-black-2: rgba(0, 0, 0, 0.15);
$shadow-black-3: rgba(0, 0, 0, 0.1);
$shadow-black-4: rgba(0, 0, 0, 0.03);
$shadow-blue-1: rgba(0, 55, 234, 0.35);
$shadow-blue-2: rgba(0, 44, 187, 0.07);
$shadow-blue-3: rgba(192, 206, 234, 0.36);
$shadow-grey: rgba(85, 97, 100, 0.16);

//border-radius
$border-radius-xl: 24px;
$border-radius-l: 16px;
$border-radius: 8px;
$border-radius-s: 4px;

//transitions
$transition-time: 0.2s;
$transition-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

$mobile-breakpoint: 1100px;
