@import "variables";
@import "layout";

.snackbarWrapper {
  .spacer {
    height: 32px;
  }

  .contentWrapper {
    position: fixed;
    z-index: 20;
    top: 100px;
    left: 100px;
    right: 0;
    background-color: $color-error-10;
    color: $color-error-90;
    text-align: center;
    padding: 8px 0 6px;
    display: flex;
    justify-content: center;

    a,
    button {
      margin-left: 3px;
      text-transform: uppercase;
      color: $color-error-90;
      background-color: $color-error-10;
      text-decoration: underline;
      font-family: $font-soleil-bold;
      border: none;
      cursor: pointer;
    }

    .closeButton {
      cursor: pointer;
      position: absolute;
      height: 24px;
      right: 12px;
      top: 3px;
    }

    &.color-green {
      background-color: $color-success-10;
      color: $color-success-90;

      a,
      button {
        color: $color-success-90;
        background-color: $color-success-10;
      }

      svg {
        path {
          stroke: $color-success-90;
        }
      }
    }
  }
}
