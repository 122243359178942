@import "variables";
@import "mixins";

.marker {
  position: absolute;
  top: -11.5px;
  left: 0;
  width: 14.3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  transition: transform 0.5s $transition-easing 0.2s;
  transform: translateX(100%);

  div {
    width: 20px;
    height: 20px;
    background: $color-white;
    border-style: solid;
    border-color: $color-blue-40;
    border-width: 1px 0 0 1px;
    transform: rotate(45deg);
  }
}