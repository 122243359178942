@import "variables";
@import "mixins";

.report {
  .noProjects {
    width: 100%;
    min-height: calc(100vh - 210px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filtersRow {
    display: flex;
    align-items: center;
  }

  .strokeIconActive {
    background-color: yellow !important;

    svg {
      stroke: $color-blue-70 !important;

      g {
        g {
          stroke: $color-blue-70 !important;
        }
      }
    }
  }
}
