@import "variables";
@import "mixins";

.total {
  margin-right: 10px;
  font-family: $font-soleil-light;
  font-size: 1.8rem;
  color: $color-flint-60;

  @extend .font-fix;
}

.totalTimeWrapper {
  display: flex;
  justify-content: flex-end;
}