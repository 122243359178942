@import 'variables';
@import 'mixins';

.wrapper {
  display: flex;
  margin-left: 17px;

  .avatar {
    margin-left: -17px;
    border: 2px solid $color-white;
    border-radius: 100%;
  }

  .moreAvatars {
    margin-left: -17px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 2px solid $color-white;
    background-color: $color-sage-20;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: $color-blue-70;
      font-family: $font-soleil-bold;
    }
  }
}
