@import "variables";

strong {
  font-family: $font-soleil-bold;
}

.declaration {
  position: relative;

  label {
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: $color-flint-60;
  }
}

.info {
  display: flex;
  border-radius: 8px;
  background-color: $color-blue-00;
  color: $color-blue-80;
  padding: 4px 8px;
  font-size: 1.2rem;
}