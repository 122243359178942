@import "variables";
@import "../styles/mixins";

.wrapper {
  display: inline-block;
}

.tooltip {
  position: absolute;
  width: 200px;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .content {
    background: $color-flint-95;
    max-width: 200px;
    min-width: 20px;
    padding: 10px;
    color: $color-white;
    border-radius: $border-radius;
    text-align: center;
    font-family: $font-soleil;
    line-height: 1.5em;
    word-break: normal;

    @include box-shadow($shadow-blur-md, $shadow-black-2);
  }

  &:after {
    position: absolute;
    width: 12px;
    height: 12px;
    background: $color-flint-95;
    transform: rotate(45deg);
    content: '';
    transform-origin: 0 0;
  }

  &.position-bottom {
    &::after {
      top: -8px;
      left: 50%;
    }
  }

  &.position-top {
    &::after {
      top: calc(100% - 8px);
      left: 50%;
    }
  }
}
