@import "variables";
@import "mixins";

.projectsList {
  .headerRight {
    display: flex;

    .search {
      margin: 0 16px;
      max-width: 320px;

      @media screen and (max-width: 1350px) {
        max-width: 250px;
      }
    }
  }

  .archiveSwitch {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $color-sage-10;
    border-radius: 22.5px;
    padding: 8px 10px;
    background: $color-white;


    span{
      font-size: 1.4rem;
      color: $color-sage-70;
      font-family: $font-soleil-light;
      @extend .font-fix;
    }
  }

  .list {
    display: flex;
    flex-direction: column;

    .row {
      position: relative;
      height: 64px;
      background-color: $color-white;
      border-radius: $border-radius;
      overflow: hidden;
      display: flex;
      flex-direction: row;

      &:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
        background-color: var(--color);
        content: ''
      }

      .name {
        display: flex;
        flex: 1;
        font-size: 2rem;
        font-family: $font-soleil-light;
        align-items: center;
        cursor: pointer;
        color: var(--color);
      }

      .actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        .action {
          cursor: pointer;
        }
      }
    }
  }
}
