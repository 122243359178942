@import "variables";
@import "mixins";

.infoCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;

  .infoCard {
    position: relative;
    width: 432px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    border-radius: $border-radius;
    color: $color-flint-60;
    font-family: $font-soleil-light;
    font-size: 1.4rem;

    @include box-shadow($shadow-blur-md, $shadow-blue-2);

    .header {
      font-family: $font-soleil;
      font-size: 2.2rem;
      color: $color-blue-70;
      line-height: 1.5em;
      text-align: center;
    }

    .description {
      text-align: center;
    }

    .imageSpace {
      height: 80px;
    }

    .imageContainer {
      height: 140px;
      position: absolute;
      top: -60px;

      svg {
        height: 140px;
      }
    }
  }
}

