@import "variables";
@import "mixins";

.spinner {
  @include box-shadow($shadow-blur-md, $shadow-blue-2);

  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -30px;
  height: 24px;
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background: $color-blue-70;
  border-radius: 0 0 $border-radius $border-radius;
  transition: transform 0.1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
  transform: translateY(0);
  opacity: 1;

  @keyframes spinner {
    0% {
      transform: scale(0.7);
    }

    10% {
      transform: scale(0.7);
    }

    40% {
      transform: scale(1);
    }

    60% {
      transform: scale(1);
    }

    90% {
      transform: scale(0.7);
    }

    100% {
      transform: scale(0.7);
    }
  }

  div {
    margin: 0 3px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: $color-white;
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;

    &:nth-child(1) {
      animation: spinner 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) -1s infinite;
    }

    &:nth-child(2) {
      animation: spinner 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) -0.5s infinite;
    }

    &:nth-child(3) {
      animation: spinner 1.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
    }
  }

  &.hidden {
    transition: transform 0.1s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s, opacity 0.01s linear 0.3s;
    transform: translateY(-25px);
    opacity: 0;

    > div {
      opacity: 0;
    }
  }
}
