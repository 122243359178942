@import "variables";
@import "../styles/mixins";

.wrapper {
  display: flex;

  .rightSide {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 640px;

    .title {
      font-size: 1.8rem;
      margin-top: 6px;
    }

    .pro {
      background-color: $color-warning-50;
      color: $color-warning-100;
      font-family: $font-soleil-bold;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px 1px;
      border-radius: 8px;
      line-height: 20px;
    }

    .description {
      font-size: 1.2rem;
    }
  }
}
