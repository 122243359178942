@import "variables";
@import "mixins";

.pointer{
  cursor: pointer;
}

.context {
  display: block;
}
