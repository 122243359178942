@import 'variables';
@import "mixins";

.selectGroup {
  display: flex;
  justify-content: space-between;
  width: 160px;
}

.select {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 1.2rem;
  font-family: "Soleil", sans-serif;
  color: $color-flint-80;
  min-width: 94px;

  &.minorWidth {
    min-width: 60px;
  }

  .selectField {
    position: relative;
    border-bottom: solid 1px $color-sage-20;
    padding: 5px 0;

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      transition: transform $transition-time $transition-easing;
      transform-origin: 50% 50%;
      transform: scaleX(0);
      height: 2px;
      width: 100%;
      background: $color-blue-70;
    }
  }

  .selectFieldContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .selectFieldValue {
    flex: 1;
    min-width: 0;
    color: $color-flint-80;
  }
}

.optionsContainer {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 200px;
  border-radius: 0 0 6px 6px;
  box-shadow: 18px 20px 23px 0 $shadow-black-3;
  border-top: solid 1px $color-sage-10;
  background-color: $color-white;

  .option {
    font-size: 1.4rem;
    line-height: 1.2rem;
    font-family: $font-soleil;

    padding: 10px;
    border-right: solid 1px $color-sage-20;
    border-bottom: solid 1px $color-sage-10;
    border-left: solid 1px $color-sage-10;
  }
}


