@import '../styles/variables';
@import '../styles/mixins';

.pill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $font-soleil-bold;
  box-sizing: border-box;
  transition: all 0.5s $transition-easing;
  white-space: nowrap;
  pointer-events: none;

  span {
    @extend .font-fix-2;
  }

  &.size {
    &-large {
      height: 45px;
      border-radius: 23px;
      padding: 0 23px;
      font-size: 2.8rem;
    }

    &-medium {
      height: 35px;
      border-radius: 18px;
      padding: 0 18px;
      font-size: 2rem;
    }

    &-small {
      height: 25px;
      border-radius: 13px;
      padding: 0 13px;
      font-size: 1.2rem;
    }
  }

  &.color {
    &-grey {
      &.bordered {
        border: 1px solid $color-sage-10;
      }
    }

    &-white {
      border: 1px solid $color-white;
      background: $color-white;

      &.bordered {
        border: 1px solid $color-sage-10;
      }
    }
  }

  &.textColor {
    &-black {
      color: $color-flint-60;
    }

    &-blue {
      color: $color-blue-70;
    }
  }
}
