@import "variables";
@import "../styles/mixins";

.drawer {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 100px;
  right: 0;
  z-index: 20;
  pointer-events: auto;
  opacity: 1;
  transform: translateY(calc(-100% + 25px));
  transition: all 0.2s $transition-easing;

  .content {
    width: 100%;
    background: $color-white;
    box-shadow: none;

    .container {
      width: 1000px;
      display: flex;
      margin: 0 auto;
      padding: 30px 0;

      @media (min-width: 1350px) {
        width: 1100px;
      }

      @media (min-width: 1550px) {
        width: 1300px;
      }

      @media (min-width: 1750px) {
        width: 1500px;
      }
    }
  }

  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    padding: 0 40px;
    border-radius: 0 0 25px 25px;
    background: $color-white;
    cursor: pointer;
    color: $color-blue-70;
    font-family: $font-soleil-bold;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    transform: translateY(0);
    transition: transform 0.2s $transition-easing;

    span {
      text-transform: uppercase;

      @extend .font-fix;
    }

    svg {
      margin-left: 10px;
      transition: transform 0.2s $transition-easing;

      path {
        fill: $color-blue-70;
        fill-rule: evenodd;
      }
    }
  }

  &.invisible {
    opacity: 0;
    pointer-events: none;

    .button {
      transform: translateY(-25px);
    }
  }

  &.active {
    .content {
      @include box-shadow($shadow-blur-lg, $shadow-black-2);
    }

    .button {
      svg {
        transform: rotate(180deg);
      }
    }

    transform: translateY(0);
  }
}
