@import "variables";
@import "layout";

.app {
  width: 100%;
  min-height: 100vh;
  background: $color-purple-00;
  font-family: $font-soleil;
  position: relative;
  color: $color-flint-60;

  .main {
    position: relative;
    z-index: 1;

    .content {
      position: relative;
    }
  }
}
