@import "variables";

.menuItem {
  width: 100px;
  height: 88px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba($color-white, 0.1);
  text-decoration: none;


  .icon {
    width: 20px;
    height: 20px;
    margin-bottom: 15px;
  }

  .title {
    color: $color-sage-40;
    font-family: $font-soleil-light;
    transition: color 0.2s linear;
    text-transform: uppercase;
    text-decoration: none;
    max-width: 90px;
    text-align: center;
  }

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    content: '';
    width: 3px;
    background: $color-blue-70;
    transform: translateX(-3px);
    transition: transform $transition-time $transition-easing;
  }

  &.active {
    .title {
      color: $color-white;
    }

    &::after {
      transform: translateX(0);
    }
  }

  &:hover {
    .title {
      color: $color-white;
    }
  }
}
