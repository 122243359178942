@import "variables";
@import "mixins";

.question {
  font-size: 1.8rem;
  font-family: $font-soleil-bold;
}

.options {
  display: flex;
  flex-direction: column;

  .option {
    display: flex;

    input {
      margin-right: 5px;
    }
  }
}