@import "variables";

.notificationLabel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40px;

  .title {
    font-family: $font-soleil-bold;
  }

  .description {
    font-family: $font-soleil-light;
  }
}
