@import "variables";
@import "mixins";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .inputs {
    display: flex;
    flex-direction: row;
  }
}

.inputContainer {
  display: flex;
  flex-direction: row;
  width: 100%;

  .inputWithPadding {
    flex: 1;
    padding-left: 10px;
  }

  .input {
    flex: 1;
    display: flex;
  }

  .tagSelect {
    flex: 1;
    width: 100%;
  }

  .switchFreeze {
    margin-right: 10px;
  }
}

.project {
  width: 270px;
}

.freeze {
  min-width: 15%;
}

.btnSave {
  justify-content: center;
  max-width: 100px;
}
