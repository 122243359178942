@import "variables";
@import "layout";

.noteFromManagerWrapper {
  display: flex;
  background-color: $color-white;
  padding: 24px;

  .rightSide {
    margin-left: 24px;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 1.8rem;
      margin-bottom: 16px;
    }
  }
}

.headerRightChildrenWrapper {
  display: flex;
  align-items: center;

  > div {
    margin-right: 12px
  }

  > button {
    margin-left: 12px;
  }
}

.title {
  display: flex;
  align-items: center;
}