@import "variables";
@import "mixins";

.feedback {
  position: fixed;
  bottom: 64px;
  left: 25px;
  width: 50px;
  height: 50px;
  background-color: $color-blue-70;
  border: none;
  border-radius: 50%;
  pointer-events: all;
  z-index: 200;
  cursor: pointer;
}
