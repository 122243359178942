@import "variables";
@import "mixins";

.card {
  @extend .view;

  .infoCardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    flex-direction: column;

    .headerContainer {
      display: flex;
      align-items: center;
      width: 580px;
      font-size: 26px;
      color: $color-sage-70;
      margin-bottom: 40px;
    }

    .header {
      display: flex;
      justify-content: flex-start;
      padding-left: 20px;
    }

    .infoCard {
      position: relative;
      width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $color-white;
      padding: 30px 60px 30px;
      border-radius: 6px;
      color: $color-flint-60;
      font-family: $font-soleil-light;
      font-size: 1.4rem;

      @include box-shadow($shadow-blur-md, $shadow-blue-2);

      .header {
        width: 100%;
        font-size: 20px;
        color: $color-sage-70;
      }

      .selectContainer {
        margin-top: 30px;
        padding-left: 20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .select {
        width: 230px;

        .selectHeader {
          display: flex;
          width: 30%;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          text-align: center;
          color: $color-sage-40;
          margin-bottom: 10px;
        }
      }

      .buttonContainer {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        margin-top: 60px;
      }
    }
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    .infoCardContainer {
      margin-top: 20px;

      .headerContainer {
        max-width: 440px;
        width: 100%;
        margin: 0 15px 20px;
      }

      .infoCard {
        width: 100%;
        max-width: 440px;
        margin: 0 15px;
        box-sizing: border-box;
        padding: 30px 15px 30px;

        .selectContainer {
          flex-direction: column;

          > div {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

