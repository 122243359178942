@import "variables";
@import "mixins";

.autotracker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $color-white;
  border: 1px solid transparent;
  border-radius: 30px;
  min-width: 110px;
  margin-right: 30px;
  padding: 5px;

  .label {
    padding: 0 15px;
    display: flex;

    @extend .font-fix;

    .title {
      padding-right: 15px;
      color: $color-flint-60;
    }

    .duration {
      font-family: $font-soleil-bold;
      color: $color-blue-70;
    }
  }
}
