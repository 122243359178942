@import "variables";
@import "mixins";

.listRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 183px;
  color: $color-sage-40;
}
