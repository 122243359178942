@import "variables";
@import "mixins";

.workspaceSettings {
  width: 100%;

  .upload {
    flex: 3;
  }

  .name {
    flex: 2;
    //width: 30%;
    //display: flex;
    //align-items: center;

    //.inputContainer {
    //  padding: 40px 20px 20px 50px !important;
    //}
  }

  .week {
    flex: 2;
    //display: flex;
    //align-items: center;
    //
    //.select {
    //  width: 100%;
    //  padding: 20px 20px 0 50px !important;
    //}
  }

  .input {
    padding-top: 20px;
  }

  .avatar {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 1px dashed $color-sage-20;
    background-color: $color-sage-20;
    background-size: cover !important;
    background-position: center center !important;

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: $color-blue-70;
      opacity: 0;
    }

    @keyframes focus-anim {
      0% {
        transform: scale(0);
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        transform: scale(1);
        opacity: 0;
      }
    }

    &.active {
      &::after {
        animation: focus-anim 1s $transition-easing infinite;
      }
    }
  }

  .avatarText {
    flex: 1;

    p {
      font-family: $font-soleil-bold;
      font-size: 1.2rem;
      line-height: 1.6rem;

      &.small {
        font-family: $font-soleil-light;
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
  }

  .button {
    justify-content: flex-end;
  }
}