@import "variables";
@import "../styles/mixins";

.checkbox {
  display: flex;
  font-family: $font-soleil;
  align-items: stretch;
  justify-content: center;
  line-height: 1.2rem;
  color: $color-flint-60;

  label {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding-left: 20px;
  }

  input[type="checkbox"] {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }

  .required {
    color: $color-error-70;
  }

  .errormessage {
    color: $color-error-70;
    position: absolute;
    top: 20px;
    left: 0;
    font-size: 1rem;
    line-height: 1rem;
    opacity: 0;
    transform: translateY(-10px);
    transition: all $transition-time $transition-easing;
  }

  .check {
    position: absolute;
    border: none;
    outline: none;
    width: 13px;
    height: 13px;
    display: flex;
    align-self: center;
    justify-content: center;
    cursor: pointer;
    transition: border-color $transition-time $transition-easing;
    border: solid 1px $color-sage-70;

    svg {
      align-self: center;
      transform: scale(0);
      transition: transform $transition-time $transition-easing;
    }

    &:hover {
      border: solid 1px $color-blue-70;
    }

    &.labeled {
      left: 0;
    }

    &.disabled {
      border: solid 1px $color-sage-20;
      cursor: default;
    }
  }

  &.checked {
    .check {
      svg {
        transform: scale(1);
      }
    }
  }

  &.error {
    .check {
      border: solid 1px $color-error-70;
    }

    .errormessage {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
