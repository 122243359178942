@import "variables";
@import "mixins";

.timeBar {
  position: relative;
  width: 100%;

  .daysBar {
    width: 100%;
    height: 140px;
    position: relative;
    overflow: hidden;

    &::after {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      bottom: 30px;
      content: "";
      background: $color-white;
      border-radius: $border-radius;
    }

    .wrapper {
      position: absolute;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 300%;
      margin: 30px 0;
      z-index: 1;

      .day {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 3;
        height: 80px;
        border-right: 1px solid transparent;
        font-family: $font-soleil;
        cursor: pointer;
        user-select: none;

        .name {
          position: relative;
          z-index: 4;
          font-size: 1.6rem;
          font-family: $font-soleil-light;
          transition: color 0.5s $transition-easing;

          span {
            @extend .font-fix;
          }
        }

        .month {
          position: absolute;
          z-index: 4;
          top: 10px;
          left: 10px;
          font-family: $font-soleil-bold;
          font-size: 1.2rem;
          color: $color-blue-70;
          opacity: 0.5;
          transition: color 0.5s $transition-easing;
        }

        .log {
          margin-top: 4px;
        }

        &.today {
          .name {
            font-family: $font-soleil-bold;
            color: $color-blue-70;
          }
        }

        &.active {
          .month {
            color: $color-white;
          }

          .name {
            font-family: $font-soleil-light;
            color: $color-white;
          }

          &.today {
            .name {
              font-family: $font-soleil-bold;
            }
          }
        }

        &:last-child {
          border-right: 0;
        }
      }

      .backgrounds {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        z-index: 1;
        width: 100%;

        > div {
          flex: 1;
          height: 80px;
        }
      }

      .markerContainer {
        position: absolute;
        top: 0;
        width: 33.333%;
        height: 80px;
        z-index: 2;
        margin: 0 33.333%;

        .marker {
          position: absolute;
          top: -10px;
          left: 0;
          height: 100px;
          width: 14.285%;
          background: $color-blue-70;
          border-radius: 6px;

          @include box-shadow($shadow-blur-md, $shadow-blue-1);

          &.animating {
            transition: transform 0.47s $transition-easing 0.03s;
          }
        }
      }

      &.animating {
        transition: transform 0.5s $transition-easing;
      }

      &.position {
        &-left {
          transform: translateX(0);
        }

        &-center {
          transform: translateX(-33.333%);
        }

        &-right {
          transform: translateX(-66.666%);
        }
      }
    }

    @keyframes movingX {
      0% {
        transform: translateX(0);
      }
      30% {
        transform: translateX(30px);
      }
      60% {
        transform: translateX(-30px);
      }
      100% {
        transform: translateX(0);
      }
    }

    .draggingwrapper {
      position: relative;
      z-index: 1;
      animation: movingX 2s $transition-easing 0s 1;
      transition: opacity 0.3s linear;

      &.dragging {
        .wrapper {
          .day {
            pointer-events: none;
          }
        }
      }

      &.animation {
        transition: transform 0.3s $transition-easing, opacity 0.3s linear;
      }
    }
  }

  &[class*=' anim-enter'] {
    opacity: 0;
  }

  &[class*=' anim-enter-active'] {
    opacity: 1;
    transition: opacity 0.2s $transition-easing 0s;
  }

  &[class*=' anim-enter-done'] {
    opacity: 1;
  }

  &[class*=' anim-exit'] {
    opacity: 1;
  }

  &[class*=' anim-exit-active'] {
    opacity: 0;
    transition: opacity 0.2s $transition-easing 0s;
  }
}
