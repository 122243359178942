@import "variables";
@import "mixins";

.calendar {
  position: relative;
  top: 12px;
  display: flex;
  flex-direction: row;
  width: 720px;
  border: solid 1px $color-sage-20;
  border-radius: $border-radius;
  background: $color-white;
  font-family: $font-soleil;

  @include box-shadow($shadow-blur-lg, $shadow-black-2);

  .month {
    display: flex;
    flex-direction: column;
    width: 252px;
    margin-right: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: $font-soleil;

    &:first-child {
      margin-left: 25px;
    }

    .header {
      height: 25px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      .name {
        font-size: 1.4rem;
        color: $color-flint-80;
        user-select: none;
      }

      > div {
        min-width: 25px;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $color-purple-00;
        cursor: pointer;

        &:hover {
          background: $color-sage-20;
        }
      }
    }

    .weeknames {
      width: 252px;
      height: 40px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid $color-sage-20;
      user-select: none;

      > div {
        width: 30px;
        flex: 1;
        text-align: center;
        font-size: 1.2rem;
        color: $color-flint-60;

        &:nth-child(6),
        &:nth-child(7) {
          color: $color-sage-40;
        }

        span {
          @extend .font-fix;
        }
      }
    }

    .days {
      position: relative;
      overflow: hidden;
      font-size: 1.2rem;
      height: 192px;
      margin-top: 10px;
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 140px;
    border-left: 1px solid $color-sage-20;
    justify-content: center;

    a.menuLink {
      margin-bottom: 10px;
      padding: 5px 15px;
      color: $color-sage-20;
      font-size: 1.3rem;
      border-left: 2px solid transparent;
      cursor: pointer;

      &.active {
        color: $color-blue-70;
        font-family: $font-soleil-bold;
        border-left: 2px solid $color-blue-70;
      }
    }
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 50px;
    width: 12px;
    height: 12px;
    background: $color-white;
    margin-left: -7px;
    margin-top: -7px;
    transform: rotate(45deg);
    border-top: solid 1px $color-sage-20;
    border-left: solid 1px $color-sage-20;
  }
}
