@import "variables";
@import "mixins";

.workspace {
  display: flex;
  flex-direction: row;
  padding: 12px 20px;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $color-sage-20;
  }

  .logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: $color-sage-20;
    background-position: center !important;
    background-size: cover !important;
    margin-right: 15px;
  }

  .name {
    flex: 1;
    font-size: 1.4rem;
    font-family: $font-soleil-light;
    color: $color-flint-95;
  }

  &:hover {
    background: $color-purple-10;
  }

  &.status {
    &-invited {
      opacity: 0.5;
    }
  }
}
