@import "variables";
@import "mixins";

.listHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  opacity: 1;
  transition: opacity $transition-time $transition-easing;

  @include box-shadow($shadow-blur-sm, $shadow-blue-2);

  &.docked {
    box-shadow: none;
  }

  &.hidden {
    opacity: 0;
  }
}
