@import "variables";
@import "mixins";

.filters {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: stretch;
  background: $color-white;
  border-radius: $border-radius;

  > div {
    flex: 1;

    .select {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: flex-end;
      height: 50px;
    }

    .suggestions {
      transition: all .2s linear;

      &.hidden {
        transform: translateY(14px);
      }
    }
  }
}
