@import "variables";
@import "mixins";

.subscription {
  .buttonsWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .buttons {
      background-color: $color-purple-20;
      display: flex;
      width: 340px;
      border-radius: $border-radius-xl;
      position: relative;

      .save20 {
        background-color: $color-error-70;
        color: white;
        padding: 8px 6px 4px;
        position: absolute;
        top: -26px;
        right: 0;
        border-radius: 8px 8px 8px 0;
      }

      button {
        white-space: nowrap;
      }
    }
  }

  .cards {
    display: flex;
    justify-content: center;
  }

  .comparePlans {
    font-size: 1.6rem;
    color: $color-blue-70;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
    text-transform: uppercase;
    text-decoration: none;

    svg {
      margin-top: -3px;
      margin-left: 10px;
    }
  }
}
