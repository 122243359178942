@import "variables";
@import "mixins";

.table {
  border: 1px solid $color-purple-20;
  border-radius: $border-radius;

  @include box-shadow($shadow-blur-md, $shadow-blue-2);

  .total {
    font-size: 1.4rem;
    line-height: 2rem;
    align-items: center;
    justify-content: space-between;
    font-family: $font-soleil-bold;
    border-bottom: 1px solid $color-purple-20;
  }

  .projects {
    overflow: hidden;
    overflow-y: auto;
    max-height: 300px;

    .project {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 2rem;
    }
  }
}