@import "variables";
@import "mixins";

.description {
  font-size: 1.4rem;
  line-height: 2rem;
}

.messageWrapper {
  border: 1px solid $color-purple-20;
  border-radius: 8px;
  overflow-y: auto;
  height: 100px;

  .message {
    color: $color-sage-40;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}
