@import 'variables';
@import 'mixins';

.avatar {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  box-sizing: border-box;
  position: relative;
  border-width: 1px;

  &.signature {
    border: 1px solid transparent;
    background: $color-white;
    font-family: $font-soleil-bold;
    color: var(--color);
    font-size: 1.2rem;

    span {
      @extend .font-fix;
    }
  }

  img {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 36px;
    height: 36px;
    border-radius: 18px;
  }
}
