@import "variables";
@import "mixins";

.pillBar {
  display: flex;
  position: relative;
  background-color: $color-white;
  padding: 4px;
  border-radius: 48px;

  .option {
    position: relative;
    font-size: 1.2rem;
    line-height: 1;
    font-family: $font-soleil-bold;
    color: $color-sage-40;
    background-color: transparent;
    border: none;
    padding: 8px 16px;
    border-radius: 18px;
    cursor: pointer;
    transition: all 0.2s $transition-easing;

    .label {
      position: relative;
      z-index: 2;

      span {
        @extend .font-fix;
      }
    }

    &.active {
      color: $color-blue-70;
    }
  }

  .pill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-purple-20;
    border-radius: 14px;
  }
}