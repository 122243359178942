@import 'variables';
@import 'mixins';

.status {
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;

  > div {
    margin-right: 10px;
  }

  &.rejected  {
    color: $color-error-80;
  }
}
