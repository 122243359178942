@import "variables";
@import "mixins";

.name {
  font-size: 1.4rem;
  font-family: $font-soleil-bold;
}

.description {
  font-size: 1.4rem;
  margin-bottom: 20px;
}

.disclaimer {
  margin-top: 16px;
  font-size: 1.2rem;
}

