@import "variables";
@import "mixins";

.context {
  position: relative;
  top: 12px;
  width: 260px;
  border: solid 1px $color-sage-20;
  border-radius: $border-radius;
  background: $color-white;
  font-family: $font-soleil;

  @include box-shadow($shadow-blur-lg, $shadow-black-2);

  > div:not(:last-child) {
    border-bottom: solid 1px $color-sage-20;
  }

  .month {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    box-sizing: border-box;

    .name {
      font-size: 1.2rem;
      color: $color-flint-60;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: $color-purple-00;
      cursor: pointer;

      &:hover {
        background: $color-sage-20;
      }

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .weeknames {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 7px;
    box-sizing: border-box;

    > div {
      flex: 1;
      text-align: center;
      font-size: 1.2rem;
      color: $color-flint-60;

      &:nth-child(6),
      &:nth-child(7) {
        color: $color-sage-40;
      }

      span {
        @extend .font-fix;
      }
    }
  }

  .view {
    width: 100%;
    height: 222px;
    position: relative;
    overflow: hidden;
    font-size: 1.2rem;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    width: 12px;
    height: 12px;
    background: $color-white;
    margin-left: -7px;
    margin-top: -7px;
    transform: rotate(45deg);
    border-top: solid 1px $color-sage-20;
    border-left: solid 1px $color-sage-20;
  }
}
