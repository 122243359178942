@import "variables";

.menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-flint-95;
  transform: translateY(0);
  transition: transform $transition-time $transition-easing;

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    background: $color-blue-70;
    overflow: hidden;

    svg {
      width: 30px;
    }
  }

  .version {
    width: 100px;
    padding: 20px 10px;
    box-sizing: border-box;
    text-align: center;
    color: $color-flint-60;
    font-size: 1rem;
    cursor: pointer;
  }

  &.hidden {
    transform: translateX(-100px);
  }

  &.disabled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100px;
    height: 100vh;
    z-index: 1;
  }

  .timesheets {
    font-size: 1.2rem;
    font-family: $font-soleil-bold;
  }
}
