@import "../styles/variables";
@import "../styles/mixins";

.root {
  display: flex;
  flex-direction: row;
  padding: 10px 0 0;
  margin-bottom: 10px;
  color: $color-flint-80;
  align-items: center;

  .icon {
    width: 62px;
    margin-right: 31px;
  }

  .content {
    max-width: 480px;

    .title {
      font-size: 2.2rem;
      margin-bottom: 16px;
      font-family: $font-soleil-bold;
    }

    .description {
      font-size: 1.4rem;
      line-height: 20px;

      a {
        margin-left: 2px;
        color: $color-blue-70;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}
