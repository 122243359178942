@import "variables";
@import "mixins";

.monthView {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 252px;
  box-sizing: border-box;
  font-family: $font-soleil;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .day {
    display: flex;
    flex-grow: 1;
    width: 36px;
    height: 30px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 2px;
    color: $color-flint-60;

    span {
      position: relative;
      z-index: 3;
      @extend .font-fix-2;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 3px;
      left: 3px;
      bottom: 0;
      border-radius: 15px;
      content: '';
      z-index: 2;
      background: $color-blue-70;
      transition: all 0.2s $transition-easing;
      transform: scale(0);
      opacity: 0;
    }

    &.transparent {
      opacity: 0.2;

      &::before {
        display: none;
      }
    }

    &.today {
      color: $color-blue-70;
    }

    &.ranged {
      &::after {
        position: absolute;
        top: 2px;
        left: 0;
        right: 0;
        bottom: 2px;
        content: '';
        z-index: 1;
        background: $color-purple-10;
      }

      &:nth-child(7n) {
        &::after {
          border-radius: 0 13px 13px 0;
          right: 3px;
          background: $color-purple-10;
        }
      }

      &:nth-child(7n + 1) {
        &::after {
          border-radius: 13px 0 0 13px;
          left: 3px;
          background: $color-purple-10;
        }
      }
    }

    &.rangeStart {
      color: $color-white;

      &::before {
        transform: scale(1);
        opacity: 1;
      }

      &::after {
        position: absolute;
        top: 2px;
        right: 0;
        left: 3px;
        bottom: 2px;
        border-radius: 13px 0 0 13px;
        content: '';
        z-index: 1;
        background: $color-purple-10;
      }

      &:nth-child(7n) {
        &::after {
          right: 3px;
          border-radius: 13px;
        }
      }
    }

    &.rangeEnd {
      color: $color-white;

      &::before {
        transform: scale(1);
        opacity: 1;
      }

      &::after {
        position: absolute;
        top: 2px;
        left: 0;
        right: 3px;
        bottom: 2px;
        border-radius: 0 13px 13px 0;
        content: '';
        z-index: 1;
        background: $color-purple-10;
      }

      &:nth-child(7n + 1) {
        &::after {
          left: 3px;
          border-radius: 13px;
        }
      }
    }

    &.rangeEnd.rangeStart {
      &::after {
        border-radius: 50%;
      }
    }

    &.selected {
      color: $color-white;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        content: '';
        z-index: 2;
        background: $color-blue-70;
        animation: todayEnter 0.2s $transition-easing;
      }
    }

    &.disabled {
      color: $color-sage-20;
      pointer-events: none;

      &::before {
        display: none;
      }

      &::after {
        opacity: 0.2;
      }
    }
  }

  @keyframes todayEnter {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }
}
