@import "mixins";
@import "variables";

.autocomplete {
  display: flex;
  position: relative;
  outline: none;
  width: 100%;
  height: 26px;
  border-bottom: 1px solid $color-sage-20;

  .input {
    position: relative;
    font-family: $font-soleil;
    font-size: 1.4rem;
    line-height: 1.6rem;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: none;
    background-color: transparent !important;

    label {
      color: $color-sage-70;
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-family: $font-soleil-light;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 0;
      transition: all $transition-time $transition-easing;
      transform: scale(1) translate(0, 0);
      transform-origin: left top 0;
      padding: 5px 0;

      span {
        margin-left: 6px;
        color: $color-error-70;
      }

      &:focus {
        outline: none;
      }
    }

    .hint {
      position: absolute;
      display: block;
      opacity: 1;
      left: 0;
      transition: opacity $transition-time $transition-easing 0ms;
      top: 8px;
      color: $color-sage-70;
      font-size: 1.2rem;
      line-height: 1rem;
      font-family: $font-soleil-light;
      pointer-events: none;
    }

    .bar {
      position: absolute;
      bottom: 0;
      display: block;
      width: 100%;
    }

    .bar::before,
    .bar::after {
      content: '';
      height: 2px;
      width: 0;
      bottom: 1px;
      position: absolute;
      background: $color-blue-70;
      transition: transform $transition-time $transition-easing;
      transform: scaleX(0);
    }

    .bar::before {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
    }

    .bar::after {
      display: none;
      right: 50%;
    }

    .errormessage {
      color: $color-error-70;
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 5px;
      font-size: 1rem;
      opacity: 0;
      transform: translateY(-10px);
      transition: all $transition-time $transition-easing;
    }

    input {
      position: relative;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: $color-blue-70;
      font-family: $font-soleil-bold;
      border: none;
      background-color: transparent !important;
      width: 100%;
      opacity: 0;
    }

    .value {
      opacity: 1;
      position: absolute;
      width: 100%;
      top: 0;
      pointer-events: none;
      color: $color-blue-70;
      font-family: $font-soleil-bold;
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 5px 0;
      overflow: hidden;
      white-space: nowrap;
    }

    .chip {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 12.5px;
      height: 20px;
      background-color: $color-purple-20;
      color: $color-blue-70;
      padding: 0 8px;
      width: fit-content;
      margin-right: 4px;
      max-width: inherit;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: -10px;
      font-family: $font-soleil-light;

      svg {
        margin-left: 4px;
        cursor: pointer;
      }
    }

    .count {
      height: 20px;
      padding: 0 8px;
      border-radius: 12.5px;
      background-color: $color-sage-20;
    }

    &.filled {
      label {
        left: 0 !important;
        transform: scale(0.8) translate(0, -28px);
      }

      .hint {
        display: none !important;
        opacity: 0 !important;
      }
    }

    input:focus {
      outline: none;

      ~ label {
        left: 0 !important;
        transform: scale(0.8) translate(0, -28px);
      }

      ~ .hint {
        display: block;
        opacity: 1;
        left: 0;
      }

      ~ .bar::before,
      ~ .bar::after {
        transform: scaleX(1);
      }
    }

    &.error {
      .errormessage {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.inputActive {
      input {
        opacity: 1;
      }

      > div {
        > svg {
          rotate: 180deg;
        }
      }

      .text {
        opacity: 0;
      }
    }
  }

  .optionsContainer {
    //width: 100%;
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    margin-top: 1px;
    z-index: 1000;
    overflow-x: hidden;

    border-radius: $border-radius;
    background-color: $color-white;
    border: 1px solid $color-sage-10;
    min-width: 200px;
    max-height: 400px;
    overflow-y: auto;
    filter: drop-shadow(0px 4px 16px rgba(192, 206, 234, 0.36));

    @extend .scroll;

    &[class*=' anim-enter'] {
      opacity: 0;
    }

    &[class*=' anim-enter-active'] {
      opacity: 1;
      transition: opacity 0.2s $transition-easing 0s;
    }

    &[class*=' anim-enter-done'] {
      opacity: 1;
      transition: opacity 0.2s $transition-easing 0s;
    }

    &[class*=' anim-exit'] {
      opacity: 1;
    }

    &[class*=' anim-exit-active'] {
      opacity: 0;
      transition: opacity 0.2s $transition-easing 0s;
    }

    .options {
      max-height: 227px;
      overflow-y: auto;
    }

    .dropdownWrapper {
      display: flex;
      align-items: center;
    }

    .checkbox, .noOptionsContainer {
      display: flex;
      font-family: $font-soleil;
      align-items: stretch;
      justify-content: center;
      line-height: 1.2rem;
      color: $color-flint-60;
      cursor: pointer;

      &.active {
        background-color: $color-purple-10;

        .onlyButton {
          opacity: 1;
        }
      }

      .onlyButton {
        opacity: 0;
      }

      label {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 30px;
        cursor: pointer;
      }

      .checkboxInput {
        position: absolute;
        margin-left: -9999px;
        visibility: hidden;
      }

      .check {
        position: absolute;
        top: 15px;
        left: 20px;
        outline: none;
        width: 13px;
        height: 13px;
        display: flex;
        align-self: center;
        justify-content: center;
        transition: border-color $transition-time $transition-easing;

        svg {
          align-self: center;
          transform: scale(0);
          transition: transform $transition-time $transition-easing;
        }
      }

      &.dropdown {
        @extend .dropdown-list;

        &.only {
          justify-content: space-between;
        }
      }

      &.dropdownAll {
        @extend .dropdown-list;

        border-bottom: solid 1px $color-sage-20 !important;
      }

      &.checked {
        .check {
          svg {
            transform: scale(1);
          }
        }
      }
    }

    &.selectAll {
      align-items: center;
      justify-content: right;
    }

    .noOptions {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $color-sage-20;
    }

    .showAll {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: solid 1px rgba(225, 227, 232, 0.5);
      border-left: solid 1px rgba(225, 227, 232, 0.5);
    }
  }

  .notActive {
    display: none;
  }

  &.disabled {
    pointer-events: none;

    input {
      color: $color-sage-40;
    }
  }
}
