@import 'variables';
@import 'mixins';

.tag {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 1.2rem;
  font-family: $font-soleil-light;
  margin: 5px 10px 5px 0;
  white-space: nowrap;
  border: 1px solid transparent;
  max-width: 200px;

  .text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 1.2;

    @extend .font-fix;
  }

  .avatar {
    margin-right: 10px;
  }

  .icon {
    display: flex;
    margin-left: 10px;
    justify-content: center;
  }

  .remove {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  &.size {
    &-medium {
      height: 25px;
      border-radius: 13px;

      &.padding-left {
        padding-left: 10px;
      }

      &.padding-right {
        padding-right: 10px;
      }

      .remove {
        width: 25px;
        height: 25px;
      }
    }

    &-large {
      height: 35px;
      border-radius: 18px;

      &.padding-left {
        padding-left: 16px;
      }

      &.padding-right {
        padding-right: 16px;
      }

      .remove {
        width: 35px;
        height: 35px;
      }
    }
  }

  &.type {
    &-normal {
      background: $color-purple-00;
      color: $color-flint-60;
    }

    &-highlighted {
      background: $color-purple-10;
      color: $color-blue-90;
    }

    &-stroked {
      border: 1px solid var(--color);
      color: var(--color);
      background: transparent;
    }

    &-dashed {
      border: 1px dashed var(--color);
      background: transparent;
      color: var(--color);
    }
  }
}
