@import "variables";
@import "mixins";

.usersList {
  display: flex;
  flex-direction: column;

  .roleTable {
    .credentialsAndAvatar {
      display: flex;
      align-items: center;
    }

    .credentials {
      padding-left: 25px;
      display: inline-block;
    }

    .roleSelect {
      max-width: 80%;
    }

    .pending {
      font-family: $font-soleil-bold;
      font-size: 1.2rem;
      color: $color-flint-60;
      text-transform: uppercase;
    }
  }
}

