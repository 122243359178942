@import "variables";
@import "mixins";

.datePicker {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  background: $color-white;
  outline-style: none;
  box-shadow: none;

  .right {
    .button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: transparent;
      border: solid 1px $color-sage-10;
      cursor: pointer;
      outline-style: none;
      box-shadow: none;
    }
  }

  .left {
    display: flex;
    flex-direction: row;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: $color-sage-10;
      cursor: pointer;

      &:hover {
        background: $color-sage-20;
      }

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .center {
    width: 140px;
    padding: 0 20px;
    font-family: $font-soleil-bold;
    font-size: 1.4rem;
    color: $color-blue-70;
    text-align: center;

    span {
      @extend .font-fix;
    }
  }

  &.size {
    &-small {
      height: 36px;
      padding: 6px;
      border: solid 1px $color-sage-10;
      border-radius: $border-radius-l;

      .left {
        a {
          width: 24px;
          height: 24px;
        }
      }

      .center {
        width: 110px;
        padding: 0 20px;
        text-align: left;
      }

      .right {
        .button {
          width: 24px;
          height: 24px;
          box-sizing: border-box;
        }
      }
    }

    &-medium {
      height: 45px;
      padding: 4px;
      border: solid 1px $color-sage-10;
      border-radius: $border-radius-xl;

      .right {
        .button {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}
