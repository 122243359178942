@import "variables";

@mixin box-shadow($blur, $color) {
  box-shadow: 0 5px $blur 0 $color;
}

.font-fix {
  display: inline-block;
  transform: translateY(var(--font-fix));
}

.font-fix-2 {
  display: inline-block;
  transform: translateY(calc(2 * var(--font-fix)));
}

.scroll {
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-flint-60;
    border: 4px solid transparent;
    border-radius: 4px;
  }
}

.dropdown-list {
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  position: relative;
  justify-content: flex-start;

  &:last-child {
    border-bottom: none;
  }
}

.view {
  width: 100%;

  &[class*=' anim-enter'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &[class*=' anim-enter-active'] {
    opacity: 1;
    transition: opacity 0.5s $transition-easing;
  }

  &[class*=' anim-enter-done'] {
    opacity: 1;
    position: relative;
    top: auto;
    left: auto;
  }

  &[class*=' anim-exit'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
  }

  &[class*=' anim-exit-active'] {
    opacity: 0;
    transition: opacity 0.5s $transition-easing;
  }
}

