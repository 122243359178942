@import "variables";
@import "mixins";

.title {
  display: flex;
  align-items: center;

  div {
    margin-right: 10px;
  }
}
