@import "variables";
@import "mixins";

.label {
  color: $color-sage-40;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: $font-soleil;
  height: 20px;

  &.size {
    &-small {
      font-size: 1.2rem;
      margin-right: 5px;
      height: 20px;

      > div {
        margin-right: 5px;
      }

      span {
        @extend .font-fix;
      }
    }

    &-medium {
      color: $color-flint-60;
      font-family: $font-soleil-light;
      font-size: 1.6rem;

      > div {
        margin-right: 10px;
      }

      span {
        @extend .font-fix;
      }
    }
  }

  &.disabled {
    color: $color-sage-20;
  }
}
