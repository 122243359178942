@import "variables";
@import "mixins";

.list {
  width: 100%;
  border-radius: $border-radius;
  background-color: $color-white;
  font-family: $font-soleil;

  @keyframes animAppear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: animAppear 0.5s $transition-time 1;
  animation-fill-mode: both;

  .title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    height: 60px;
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: 1px solid $color-sage-20;
    overflow: hidden;
    background: $color-white;
    z-index: 1;

    .label {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 30px;
      font-size: 2rem;
      font-family: $font-soleil-light;

      @extend .font-fix;

      span {
        margin-left: 20px;
      }

      .icon {
        margin-left: 15px;
      }

      .rightIcon {
        margin-right: 30px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 25px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &.active {
    position: relative;
    z-index: 1;
  }

  &.noListShadow {
    box-shadow: none;
  }
}
