@import "variables";
@import "mixins";

.egg {
  width: 100vh;
  height: 100vh;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;

  @keyframes radzio {
    0% {
      opacity: 0;
      transform: translate3d(-600px, 600px, 0) rotate(40deg);
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: translate3d(600px, -600px, 0) rotate(40deg);
    }
  }

  .radImg {
    position: absolute;
    animation: radzio 2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s 1;
    animation-fill-mode: both;
  }

  .agaImg{
    position: absolute;
    left: calc(50vw - 120px);
    top: calc(50vh - 120px);
    width: 240px;
    height: 240px;
    z-index: 100;

    img {
      width: 240px;
      height: 240px;
    }

    .cloud {
      position: absolute;
      top: -20px;
      left: -20px;
      width: 100px;
      padding: 10px;
      background-color: $color-white;
      border-radius: 20px;
      font-family: $font-soleil-bold;
      font-size: 1.4rem;
      line-height: 1.2em;
    }
  }
}
