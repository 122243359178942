@import "variables";
@import "mixins";

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh);
  box-sizing: border-box;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 20px;

  > div {
    width: 1000px;
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 1350px) {
      width: 1100px;
    }

    @media (min-width: 1550px) {
      width: 1300px;
    }

    @media (min-width: 1750px) {
      width: 1500px;
    }
  }

  &.marginTop {
    padding-top: 100px;
  }

  &.marginLeft {
    padding-left: 100px;
  }
}
