@import "variables";
@import "mixins";

.listCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  overflow: visible;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-family: $font-soleil-light;
  border-right: 1px solid $color-sage-20;
  position: relative;
  box-sizing: border-box;

  .children {
    position: relative;
    z-index: 2;
    width: 100%;
    text-overflow: ellipsis;

    @extend .font-fix;
  }

  &.align {
    &-left {
      .children {
        text-align: left;
      }
    }

    &-center {
      .children {
        text-align: center;
      }
    }

    &-right {
      .children {
        text-align: right;
      }
    }
  }

  &.header {
    justify-content: center;
    font-family: $font-soleil-bold;
    border: 0;
    border-bottom: 1px solid $color-sage-20;
  }

  &.footer {
    justify-content: center;
    font-family: $font-soleil-bold;
    font-size: 1.4rem;
    border: 0;
  }

  &.clickable {
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-radius: $border-radius;
      border: 1px solid $color-blue-70;
      opacity: 0;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  &.last {
    border-right: 0;
  }
}
