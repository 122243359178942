@import "variables";
@import "mixins";

.topBar {
  @include box-shadow($shadow-blur-md, $shadow-blue-2);

  position: fixed;
  top: 0;
  left: 100px;
  right: 0;
  height: 100px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: $color-white;
  transform: translateY(0);
  transition: transform $transition-time $transition-easing;

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    padding: 0 60px;

    .profile {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      color: $color-sage-40;
      cursor: pointer;
      outline-style: none;
      box-shadow: none;

      .user {
        height: 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 8px;
        position: relative;

        .name {
          font-family: $font-soleil-light;
        }

        .workspaceName {
          margin-top: 4px;
          font-family: $font-soleil-bold;
          color: $color-blue-70;
        }
      }

      .row {
        font-family: $font-soleil-bold;
      }

      .iconContainer {
        margin-left: 20px;
      }
    }
  }

  .loader {
    @include box-shadow($shadow-blur-md, $shadow-blue-2);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    z-index: 100;
    background: $color-blue-70;

    @keyframes spinner {
      0% {
        transform: scaleX(0);
      }

      50% {
        transform: scaleX(1);
      }

      100% {
        transform: scaleX(0);
      }
    }

    > div {
      position: absolute;
      top: 0;
      left: 50%;
      width: 200px;
      height: 5px;
      margin-left: -100px;
      opacity: 0;
      transform: scaleX(0);
      transition: all 1.6s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: $color-white;
        animation: spinner 1.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite alternate none;
      }
    }

    &.show {
      > div {
        opacity: 1;
        transform: scaleX(1);
        transition: all 1.6s cubic-bezier(0.645, 0.045, 0.355, 1) 0.5s;
      }
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    content: '';
    background: $color-blue-70;
  }

  &.hidden {
    transform: translateY(-100px);
    box-shadow: none;
  }
}
