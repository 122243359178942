@import "variables";
@import "mixins";

.listRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60px;
  position: relative;
  border: 0 solid;

  &.status {
    &-edit {
      z-index: 99;

      &::after {
        border: 1px solid $color-blue-70;
        opacity: 1;
      }
    }

    &-changed {
      z-index: 99;

      @keyframes animChanged {
        0% {
          background: transparent;
        }

        50% {
          background: $color-purple-10;
        }

        100% {
          background: transparent;
        }
      }

      animation: animChanged 0.3s $transition-time 2;
      animation-fill-mode: both;
    }

    &-deleted {
      overflow: hidden;
      opacity: 0;
      height: 0;
      transition: all 0.5s $transition-easing 0.5s;

      [class*='ListActions'] {
        display: none;
      }

      @keyframes animDeleting {
        0% {
          transform: scaleX(0);
        }

        100% {
          transform: scaleX(1);
        }
      }

      &::before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        content: '';
        background: $color-error-10;
        border-radius: $border-radius;
        transform-origin: 0 0;
        z-index: 2;
        animation: animDeleting 1s linear;
        animation-fill-mode: both;
      }
    }

    &-added {
      @keyframes animAdding {
        0% {
          overflow: hidden;
          height: 0;
          opacity: 0;
        }

        50% {
          opacity: 0;
        }

        99% {
          opacity: 1;
          height: 60px;
        }

        100% {
          overflow: visible;
        }
      }

      animation: animAdding 0.5s $transition-time 1;
      animation-fill-mode: both;
    }

    &-normal,
    &-changed,
    &-added {
      &:hover {
        [class^='ListActions'] {
          opacity: 1;
          pointer-events: auto;
          transition: opacity 0.2s $transition-easing 0.2s;

          a {
            opacity: 1;
            transform: scale(1);

            &:hover {
              svg {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  &.color {
    &::after {
      border: inherit;
      border-left-width: 3px;
    }
  }

  &.hoverable {
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      top: -5px;
      content: '';
      background: white;
      border-radius: $border-radius;
      opacity: 0;
      transition: opacity 0.2s $transition-easing;
      z-index: 1;
      pointer-events: none;

      @include box-shadow($shadow-blur-lg, $shadow-blue-2);
    }

    &:hover {
      z-index: 3;

      &::after {
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
      z-index: 3;

      &::after {
        opacity: 1;
      }
    }
  }

  .label {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: blue;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}
