@import "variables";
@import "mixins";

.upgrade {
  p {
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin: 0;
  }

  a {
    font-family: $font-soleil-bold;
    color: $color-blue-70;
    margin-right: 5px;
    cursor: pointer;
  }

  strong {
    font-family: $font-soleil-bold;
  }
}