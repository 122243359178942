@import "variables";

.receiptInfo {
  width: 100%;

  .subtitle {
    font-size: 1.4rem;
    line-height: 20px;
    margin-bottom: 20px;
    max-width: 740px;
  }

  .description {
    font-size: 1.2rem;
    line-height: 18px;
    max-width: 740px;
  }

  .form {
    margin-top: 40px;

    .row {
      display: flex;
      margin-bottom: 24px;

      > div {
        width: 257px;
        max-width: 257px;
        margin-right: 32px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
  }
}
