@import "variables";
@import "../styles/mixins";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  height: 45px;

  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    cursor: pointer;
  }

  .icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 29px;
  }

  .title {
    @extend .font-fix;
  }

  .left {
    color: $color-flint-60;
    display: flex;
    align-items: center;
    font-family: $font-soleil-bold;

    .titleWrapper {
      display: flex;

      > div {
        display: flex;
        align-items: center;
        cursor: pointer;

        > div {
          margin-right: 25px;
        }

        span {
          color: $color-flint-60;
          text-decoration: none !important;
        }
      }
    }
  }

  .right {
    color: $color-flint-60;
    display: flex;
    align-items: center;
  }

  .tabs {
    color: $color-flint-60;
    display: flex;
    flex-direction: row;
    align-items: center;

    .tab {
      padding: 0 20px;
      text-decoration: none;
      font-size: 1.6rem;
      font-family: $font-soleil;
      line-height: 2rem;
      color: $color-sage-40;
      cursor: pointer;
      position: relative;
      text-align: center;

      span {
        @extend .font-fix;
      }

      &.active {
        color: $color-blue-70;

        &::after{
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -23px;
          background-color: $color-blue-70;
          height: 2px;
        }
      }
    }
  }

  &.underline {
    padding-bottom: 10px;
    border-bottom: 1px solid $color-sage-20;
  }

  &.size {
    &-small {
      .left {
        font-size: 1.6rem;
      }
    }

    &-medium {
      .left {
        font-size: 2rem;
      }
    }

    &-large {
      .left {
        font-size: 2.6rem;
      }

      .title {
        font-family: $font-soleil-bold;
      }
    }
  }
}
