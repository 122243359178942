@import "variables";
@import "mixins";

.card {
  padding: 24px;
  background-color: $color-white;
  border-radius: $border-radius-l;
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 395px;

  .top {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .icon {
      margin-bottom: 24px;
    }

    .title {
      font-size: 2.2rem;
      color: $color-blue-70;
      font-family: $font-soleil-bold;
      margin-bottom: 5px;
      text-transform: uppercase;
    }

    .priceWrapper {
      margin-bottom: 40px;
      position: relative;

      .price {
        font-size: 2.4rem;
        font-family: $font-soleil-bold;
        color: $color-flint-95;
      }

      .billed {
        position: absolute;
        left: 23px;
      }
    }

    .subtitle {
      font-size: 1.4rem;
      color: $color-flint-95;
      font-family: $font-soleil-bold;
      margin-bottom: 16px;
      width: 200px;
    }
  }

  ul {
    padding: 0;
    width: 100%;

    li {
      list-style: none;
      font-size: 1.4rem;
      margin-bottom: 16px;
      color: $color-flint-95;
      position: relative;
      display: flex;

      .tick {
        position: relative;
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        content: '';
        background: $color-purple-20;
        transform: rotate(45deg);

        &::before {
          position: absolute;
          content: '';
          top: 6px;
          right: 9px;
          width: 2px;
          height: 10px;
          background-color: $color-blue-70;
        }

        &::after {
          position: absolute;
          content: '';
          top: 14px;
          right: 9px;
          width: 6px;
          height: 2px;
          background-color: $color-blue-70;
        }
      }

      .text {
        margin-left: 13px;
        padding-top: 5px;
        text-align: left;
      }
    }

    li:first-of-type {
      font-weight: 700;
    }
  }

  button {
    width: 100%;
  }
}

.bestWrapper {
  background-color: $color-blue-70;
  border-radius: $border-radius-l;
  padding: 0 5px 5px 5px;
  justify-content: center;
  align-items: center;
  margin: -28px 25px 0;
  display: flex;
  flex-direction: column;

  span {
    color: $color-white;
    text-transform: uppercase;
    font-size: 1.6rem;
    padding: 5px 0;
    position: relative;

    svg {
      position: absolute;
      top: 16px;
      right: -35px;
    }
  }
}
