@import "variables";
@import "mixins";

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: $color-white;
}

.leftSide {
  display: flex;

  .select {
    min-width: 300px;
    width: 100%;
    margin-right: 16px;
    margin-top: 24px;
    max-width: 300px;

    .suggestions {
      transition: all .2s linear;

      &.hidden {
        transform: translateY(14px);
      }
    }
  }
}

.totalTime {
  display: flex;
  justify-content: flex-end;
  font-size: 1.8rem;
}
