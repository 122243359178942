@import "variables";
@import "mixins";

.root {
  display: flex;
  flex-direction: row;
  flex: 1;
  font-family: $font-soleil;
  font-size: 1.4rem;
  line-height: 2rem;

  strong{
    font-family: $font-soleil-bold;
    margin: 0 0.5rem;
  }

  .left {
    display: flex;
    align-items: center;
    width: 500px;
  }

  .right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }
}