@import "variables";
@import "mixins";

.userName {
  padding-right: 10px;
}

.row {
  justify-content: space-between;
  align-items: center;

  .center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}