@import "variables";
@import "mixins";

.progressCircle {
  display: block;
  position: relative;
  cursor: pointer;
  width: 45px;
  height: 45px;

  .animation {
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    width: 55px;
    height: 55px;
  }

  .children {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
