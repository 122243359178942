@import "variables";

@keyframes enter {
  0% {
    opacity: 0;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.suggestions {
  margin-top: 6px;
  height: 24px;
  max-width: 100%;
  display: flex;
  flex-direction: row;

  .chip {
    max-width:60px;
    margin: 0 4px 0 0;
    padding: 4px 8px 2px 9px;
    border-radius: 12.5px;
    border: solid 1px $color-sage-20;
    width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-sage-40;
    animation: enter ease-in .3s 1;
    animation-fill-mode: both;

    &:hover {
      cursor: pointer;
    }

    .label {
      font-family: $font-soleil;
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: $color-sage-40;
    }
  }
}
