@import "variables";
@import "mixins";

.projectsEdit {
  .deleteBtn {
    margin-left: 20px;
    display: inline-flex;
  }
}

.headerRight {
  display: flex;
  align-items: center;
}
