@import "variables";
@import "mixins";

.dropdown {
  display: inline-block;
}

.menu {
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @extend .scroll;

  .content {
    border-radius: $border-radius;
    background-color: $color-white;
    width: 220px;
    max-height: 400px;
    overflow-y: auto;
    filter: drop-shadow(0px 4px 16px rgba(192, 206, 234, 0.36));

    .section:not(:last-child) {
      border-bottom: 1px solid $color-purple-00;
    }

    .menuItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: $font-soleil;
      line-height: 1.5em;
      font-size: 1.2rem;
      color: $color-flint-60;
      cursor: pointer;
      height: 56px;

      span {
        @extend .font-fix-2;
      }
    }
  }

  &::after {
    position: absolute;
    width: 12px;
    height: 12px;
    background: $color-white;
    border-style: solid;
    border-color: rgba(225, 227, 232, 0.5);
    border-width: 1px 0 0 1px;
    transform: rotate(45deg);
    transform-origin: 0 0;
    content: '';
    top: -8px;
    left: 50%;
    z-index: 998;
  }
}
