@import "variables";

.subscription {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .icon {
    height: 100px;
  }

  .appSumoHeart {
    margin: 0 15px;
  }

  .content {
    flex: 1;

    .title {
      margin-top: 5px;
      margin-bottom: 15px;
      font-size: 2.2rem;
      color: $color-blue-70;
      font-family: $font-soleil-bold;
      text-transform: uppercase;
    }

    .subtitle {
      font-family: $font-soleil-bold;
      font-size: 1.4rem;
      margin-bottom: 16px;
    }

    .youAreCurrentlyUsing {
      font-size: 1.2rem;
      font-family: $font-soleil-bold;
      margin-bottom: 16px;
    }

    .description {
      font-size: 1.2rem;
      line-height: 1.8rem;

      a {
        text-decoration: none;
        color: $color-blue-70;
      }
    }
  }
}
