@import "variables";
@import "mixins";

.drawerFilters {
  width: 100%;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      padding: 0 20px;

      .select {
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: flex-end;
        height: 45px;
      }

      &.size-1 {
        flex: 1;
      }

      &.size-2 {
        flex: 2;
      }
    }

    .topFilters {
      display: flex;
      width: 100%;

      .datepicker {
        flex: 1;
      }

      .typeAndView {
        margin-left: -10px;
        flex: 3;
      }
    }
  }
}
