@import "variables";
@import "mixins";

.datePicker {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  background: $color-white;
  outline-style: none;
  box-shadow: none;
  min-width: 164px;

  .right {
    .button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: transparent;
      border: solid 1px $color-sage-20;
      cursor: pointer;
      outline-style: none;
      box-shadow: none;
    }
  }

  .left {
    display: flex;
    flex-direction: row;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: $color-purple-00;
      cursor: pointer;

      &.prev {
        svg {
          transform: rotate(90deg);
        }
      }

      &.next {
        svg {
          transform: rotate(-90deg);
        }
      }

      &:hover {
        background: $color-sage-20;
      }

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .center {
    padding: 0 13px 0 16px;
    font-family: $font-soleil-bold;
    font-size: 1.4rem;
    color: $color-blue-70;
    text-align: center;

    span {
      @extend .font-fix;
    }
  }

  &.size {
    &-small {
      height: 25px;
      padding: 0;

      .right {
        .button {
          width: 15px;
          height: 15px;
          padding: 5px;

          > div,
          svg {
            width: 15px;
            height: 15px;
          }
        }
      }

      .center {
        width: 140px;
        padding: 0;
        text-align: left;
      }
    }

    &-medium {
      height: 36px;
      padding: 4px;
      border-radius: $border-radius-xl;

      .right {
        .button {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.dropdown {
  position: fixed;
  z-index: 5;

  &[class*=' anim-enter'] {
    opacity: 0;
    transform: scale(0.5) translateY(-40px);
  }

  &[class*=' anim-enter-active'] {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &[class*=' anim-enter-done'] {
    opacity: 1;
    transform: scale(1) translateY(0);
  }

  &[class*=' anim-exit'] {
    opacity: 1;
    transform: scale(1) translateY(0);
  }

  &[class*=' anim-exit-active'] {
    transform: scale(0.5) translateY(-40px);
    opacity: 0;
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
