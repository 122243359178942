@import "variables";
@import "mixins";

.listDocked {
  width: 100%;
  background: $color-white;
  border-radius: $border-radius;
  font-family: $font-soleil;

  @keyframes animAppear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  animation: animAppear 0.5s $transition-time 1;
  animation-fill-mode: both;

  .title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 60px;
    border-radius: $border-radius $border-radius 0 0;
    border-bottom: 1px solid $color-purple-00;
    overflow: hidden;
    background: $color-white;
    z-index: 1;

    .label {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
    }

    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 30px;
      font-size: 2rem;
      font-family: $font-soleil-light;

      @extend .font-fix;

      .icon {
        margin-left: 15px;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 25px;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;

    .dock {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 3;
      background: $color-white;
      border-radius: 0 0 0 $border-radius;
      box-shadow: 5px 0 $shadow-blur-sm 0 $shadow-blue-2;
    }

    .well {
      display: flex;
      flex-direction: column;
      flex: 5;
      overflow: auto;
      overflow-y: hidden;
    }
  }

  @include box-shadow($shadow-blur-md, $shadow-blue-2);
}
