@import "variables";
@import "mixins";

.switch {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  padding: 4px;
  border-radius: 18px;
  background-color: $color-blue-00;
  box-sizing: border-box;
  cursor: pointer;

  .option {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 14px;

    svg {
      position: relative;
      z-index: 2;
    }

    &.disabled {
      background-color: $color-flint-30;
    }
  }

  .bg{
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: $color-blue-70;
    z-index: 1;
  }
}
