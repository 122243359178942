@import "variables";
@import "mixins";

.toast {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 248px;
  border-radius: $border-radius 0 0 $border-radius;
  overflow: hidden;

  @include box-shadow($shadow-blur-md, $shadow-blue-3);

  &::after{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    content: '';
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      align-items: center;
    }

    .text {
      flex: 1;
      flex-direction: row;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: $color-flint-80;

      span {
        @extend .font-fix;
      }
    }

    .close {
      width: 14px;
      height: 14px;
      cursor: pointer;

      svg {
        width: 14px;
        height: 14px;

        path {
          fill: $color-flint-80;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &.color {
    &-green {
      background: $color-success-10;

      &::after{
        background: $color-success-70;
      }
    }

    &-red {
      background: $color-error-10;

      &::after{
        background: $color-error-70;
      }
    }

    &-yellow {
      background: $color-warning-10;

      &::after{
        background: $color-warning-70;
      }
    }

    &-blue {
      background: #E6ECFE;

      &::after{
        background: $color-blue-80;
      }
    }
  }

  &[class*=' anim-enter'] {
    opacity: 0;
    transform: translateX(5px);

    .content {
      opacity: 0;
    }
  }

  &[class*=' anim-enter-active'] {
    opacity: 1;
    transform: translateX(0);
    transition: opacity .5s $transition-easing, transform .5s $transition-easing;

    .content {
      opacity: 1;
      transition: opacity .8s $transition-easing ;
    }
  }

  &[class*=' anim-enter-done'] {
    opacity: 1;
    transform: translateX(0);

    .content {
      opacity: 1;
    }
  }

  &[class*=' anim-exit'] {
    opacity: 1;
  }

  &[class*=' anim-exit-active'] {
    opacity: 0;
    transform: scale(0.95);
    transition: all 0.2s $transition-easing;
  }
}
