@import "variables";
@import "mixins";

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: $color-white;
}

.select {
  min-width: 160px;
  width: 100%;
  margin-right: 15px;
  margin-top: 24px;
  max-width: 300px;
}

.suggestions {
  transition: all .2s linear;

  &.hidden {
    transform: translateY(14px);
  }
}