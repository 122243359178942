@import "variables";
@import "mixins";

.top {
  display: flex;
  flex-direction: column;
  width: 100%;

  .stretched {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      display: flex;

      &:first-child > * {
        margin-right: 20px;
      }
    }
  }
}