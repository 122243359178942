@import "variables";
@import "mixins";

.error {
  .center {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .number {
      font-size: 30rem;
      font-family: $font-soleil-bold;
      color: $color-blue-70;
      text-shadow: 0 18px 13px rgba(73, 112, 239, 0.32);
    }

    .title {
      font-size: 2rem;
      font-family: $font-soleil-bold;
      color: $color-blue-70;
      margin: 0.5rem 0;
    }

    .subtitle {
      font-family: $font-soleil;
      font-size: 1.4rem;
      color: $color-flint-60;
      margin: 0.5rem 0;

      a {
        font-family: $font-soleil-bold;
        color: $color-blue-70;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0 6px;
      }
    }
  }
}
