@import "variables";
@import "../styles/mixins";

.counter {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $color-blue-70;
  color: $color-white;
  cursor: pointer;

  &.size {
    &-medium {
      border-radius: 17.5px;
      width: 35px;
      height: 35px;
      font-size: 1.2rem;
    }

    &-small {
      border-radius: 12.5px;
      width: 25px;
      height: 25px;
      font-size: 1rem;
    }
  }

  span {
    @extend .font-fix;
  }
}
