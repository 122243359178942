@import 'variables';
@import '../styles/mixins';

.dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;

  &.color {
    &-green {
      background-color: $color-success-70;
    }
    &-yellow {
      background-color: $color-warning-70;
    }
    &-red {
      background-color: $color-error-80;
    }
    &-blue {
      background-color: $color-blue-70;
    }
    &-grey {
      background-color: $color-flint-40;
    }
  }
}

