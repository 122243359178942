@import "variables";
@import "../styles/mixins";

.space {
  display: flex;

  &.direction{
    &-column{
      flex-direction: column;
    }

    &-row{
      flex-direction: row;
    }
  }
}
