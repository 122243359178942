@import "variables";
@import "mixins";

.key {
  font-family: $font-soleil-bold;
  color: $color-blue-70;
  font-size: 1.6rem;
  line-height: 2.0rem;
  font-weight: 700;
  word-break: break-all;
}
