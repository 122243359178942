@import "variables";
@import "mixins";

.error {
  .center {
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .number {
      svg {
        :global(.ufo) {
          transform-origin: 50% 50%;
          animation: movingY 4s $transition-easing 0s infinite;
        }

        :global(.shadow) {
          transform-origin: 20% 80%;
          animation: shadowAnim 4s $transition-easing 0s infinite;
        }

        :global(.circle) {
          transform-origin: 20% 80%;
          animation: shadowAnim 4s $transition-easing 0s infinite;
        }

        :global(.zero) {
          transform-origin: 20% 80%;
          animation: zeroAnim 4s $transition-easing 0s infinite;
        }
      }

      @keyframes movingY {
        0% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(100px) scale(1.3);
        }
        100% {
          transform: translateY(0);
        }
      }

      @keyframes shadowAnim {
        0% {
          transform: scaleX(1);
        }
        50% {
          transform: scaleX(0.8) scaleY(0.75);
        }
        100% {
          transform: scaleX(1);
        }
      }

      @keyframes zeroAnim {
        0% {
          transform: scaleX(1);
        }
        50% {
          transform: scaleX(1.2) scaleY(1.2);
        }
        100% {
          transform: scaleX(1);
        }
      }
    }

    .title {
      font-size: 2rem;
      color: $color-blue-70;
      margin: 0.5rem 0;
    }

    .subtitle {
      font-size: 1.4rem;
      font-family: $font-soleil-light;
      color: $color-flint-60;
      margin: 0.5rem 0;
    }

    .button {
      margin-top: 20px;
    }
  }
}
