@import "variables";
@import "mixins";

.monthView {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 222px;
  padding: 0 7px;
  box-sizing: border-box;

  > div {
    display: flex;
    flex-wrap: wrap;
  }

  .day {
    display: flex;
    flex-grow: 1;
    width: 34px;
    height: 36px;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    span {
      position: relative;
      z-index: 3;
      @extend .font-fix-2;
    }

    &.transparent {
      opacity: 0.3;
    }

    &.today {
      color: $color-blue-70;
    }

    &.ranged {
      &::after {
        position: absolute;
        top: 2px;
        left: 0;
        right: 0;
        bottom: 2px;
        content: '';
        z-index: 1;
        background: $color-purple-10;
      }

      &:nth-child(7n) {
        &::after {
          border-radius: 0 50% 50% 0;
        }
      }

      &:nth-child(7n + 1) {
        &::after {
          border-radius: 50% 0 0 50%;
        }
      }
    }

    &.rangeStart {
      &::after {
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 2px;
        width: 32px;
        border-radius: 50% 0 0 50%;
        content: '';
        z-index: 1;
        background: $color-purple-10;
      }

      &:nth-child(7n) {
        &::after {
          border-radius: 50% 50%;
        }
      }
    }

    &.rangeEnd {
      &::after {
        position: absolute;
        top: 2px;
        left: 0;
        bottom: 2px;
        width: 32px;
        border-radius: 0 50% 50% 0;
        content: '';
        z-index: 1;
        background: $color-purple-10;
      }

      &:nth-child(7n + 1) {
        &::after {
          border-radius: 50%;
        }
      }
    }

    &.rangeEnd.rangeStart {
      &::after {
        border-radius: 50%;
      }
    }

    &.selected {
      color: $color-white;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 50%;
        content: '';
        z-index: 2;
        background: $color-blue-70;
        animation: todayEnter 0.2s $transition-easing;
      }
    }

    &.disabled {
      color: $color-sage-20;
      pointer-events: none;
    }
  }

  @keyframes todayEnter {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }
}
