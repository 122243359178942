@import "variables";
@import "mixins";

.credentialsAndAvatar {
  display: flex;
  align-items: center;
}

.credentials {
  margin-left: 15px;
  max-width: 190px;

  @extend .font-fix-2;
}

.projectTag {
  padding: 5px 0;
}

.loadMoreContainer {
  margin: 20px 0;
}

.cell {
  display: inline-flex;
}

.pending {
  font-family: $font-soleil-bold;
  font-size: 1.2rem;
  color: $color-flint-60;
  text-transform: uppercase;
}

.pendingExisting {
  padding-left: 5px;

  @extend .font-fix-2;
}

.headerRight {
  display: flex;

  .search {
    margin: 0 16px;
    max-width: 320px;

    @media screen and (max-width: 1350px) {
      max-width: 250px;
    }
  }
}
