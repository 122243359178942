@import "variables";
@import "mixins";

.status {
  padding-left: 20px;

  .active,
  .inactive {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      background-color: $color-success-70;
      top: 0;
      left: -20px;
      border-radius: 50%;
    }
  }

  .active {
    &::before {
      background-color: $color-success-70;
    }
  }

  .inactive {
    &::before {
      background-color: $color-flint-95;
    }
  }
}

.credentialsAndAvatar {
  display: flex;
  align-items: center;
}

.credentials {
  margin-left: 15px;
  max-width: 190px;

  @extend .font-fix-2;
}

.projectTag {
  padding: 5px 0;
}

.loadMoreContainer {
  margin: 20px 0;
}

.cell {
  display: inline-flex;
}

.pending {
  font-family: $font-soleil-bold;
  font-size: 1.2rem;
  color: $color-flint-60;
  text-transform: uppercase;
}

.pendingExisting {
  padding-left: 5px;

  @extend .font-fix-2;
}

.headerRight {
  display: flex;

  .search {
    margin-right: 30px;
  }
}