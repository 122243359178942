@import "variables";
@import "mixins";

.block {
  display: block;
  width: 100%;

  .upload {
    width: 15%;

    .dropzone {
      flex: 0 !important;

      .dropzoneContainer {
        width: 100px;
        position: relative;

        .avatar {
          position: relative;
          width: 100px;
          height: 100px;
          border-radius: 50px;
          border: 1px dashed $color-sage-20;
          background-color: $color-purple-00;
          background-size: cover !important;
          background-position: center center !important;

          &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-color: $color-blue-70;
            opacity: 0;
          }

          @keyframes focus-anim {
            0% {
              transform: scale(0);
              opacity: 0;
            }

            50% {
              opacity: 1;
            }

            100% {
              transform: scale(1);
              opacity: 0;
            }
          }

          &.active {
            &::after {
              animation: focus-anim 0.3s $transition-easing infinite;
            }
          }
        }

        .openDropzone {
          pointer-events: none;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: absolute;
          top: 1px;
          bottom: 0;
          left: 1px;
          right: 0;
          border-radius: 50px;
          opacity: 0;
          transition: opacity 0.3s $transition-easing;

          .openDropzoneContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: -50px;

            .icon {
              margin-bottom: 10px;
            }

            button {
              padding: 10px;
              height: auto;
            }
          }

          &.active {
            opacity: 1;
          }
        }
      }
    }
  }

  .textFields {
    width: 85%;
    padding: 10px;
  }

  .row {
    display: flex;
    flex-direction: row;

    .cell {
      flex: 1;
      padding: 20px;

      &.right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  .avatarText {
    padding: 0 20px !important;
    opacity: 0;
    transition: opacity 0.5s $transition-easing;

    &.active {
      opacity: 1;
    }

    p {
      font-family: $font-soleil-light;
      color: $color-sage-40;
      text-align: left;

      &.small {
        font-size: 1rem;
        margin: 0;
      }
    }
  }

  .timezone {
    width: 30%;
    padding: 20px;
  }

  .btnContainer {
    padding-top: 0 !important;

    .saveBtn {
      padding-left: 40px;
    }
  }
}
