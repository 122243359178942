@import "variables";
@import "mixins";

.card {
  display: flex;

  .addTag {
    flex-wrap: wrap;

    .tagSelect {
      width: 300px;
      margin-bottom: -4px;
    }
  }

  .tagsGroup {
    display: flex;
    flex-direction: column;

    .tags {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.tagsGroupContainer {
  display: flex;
  justify-content: left;
  margin-bottom: 60px;
  position: relative;
  flex-direction: column;
  background-color: $color-white;
  padding: 15px 30px;
  border-radius: $border-radius;
  color: $color-flint-60;
  font-family: $font-soleil-light;
  font-size: 1.4rem;

  @include box-shadow($shadow-blur-md, $shadow-blue-2);

  .title {
    font-family: $font-soleil-light;
    font-size: 1.6rem;
  }
}
