@import "variables";
@import "mixins";

.root {
  width: 100%;
  height: 120px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $color-white;
  border: 1px solid $color-blue-40;
  border-radius: $border-radius;
  position: relative;
  box-sizing: border-box;
  z-index: 1;

  @include box-shadow($shadow-blur-md, $shadow-blue-2);

  .content {
    position: relative;
    flex: 1;
    align-self: stretch;
  }

  .switch {
    width: 36px;
  }
}
