@import "variables";
@import "mixins";
@import "layout";

.mobile_screen {
  width: 100vw;
  min-height: 100vh;
  background: $color-purple-00;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $font-soleil;
  position: relative;
  color: $color-flint-60;

  .card {
    margin-top: 40px;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    padding: 40px;
    border-radius: 6px;
    color: $color-flint-60;
    font-family: $font-soleil-light;
    font-size: 1.4rem;

    @include box-shadow($shadow-blur-md, $shadow-blue-2);

    .text {
      font-family: $font-soleil;
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-blue-70;
      text-align: center;
      margin-top: 20px;
    }

    .storeLink {
      margin-top: 20px;

      img {
        width: 154px;
      }
    }
  }
}
