@import "variables";
@import "mixins";

.subtitle {
  margin-bottom: 15px;

  .textToType {
    font-size: 1.6rem;
    font-family: $font-soleil-bold;
    color: $color-blue-70;
    margin: 20px 0;
  }
}